import { render, staticRenderFns } from "./Coaching.vue?vue&type=template&id=1312335c&scoped=true&"
import script from "./Coaching.vue?vue&type=script&lang=js&"
export * from "./Coaching.vue?vue&type=script&lang=js&"
import style0 from "./Coaching.vue?vue&type=style&index=0&id=1312335c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1312335c",
  null
  
)

export default component.exports