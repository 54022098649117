<template>
  <div class="section-jump-wrapper" v-if="showJump" :x-style="`top: ${getTopPosition.top}; --debug-head-height: ${getTopPosition.head}`">
    <div class="inner-section-jump-wrapper">
      <div class="section-group-wrap">
        <!-- <span class="edge left">&nbsp;</span> -->
        <div class="section-group">
          <a class="section-tag" v-for="section in getSections" :key="section.key" href="javascript:void(0);" @click="scrollTo" :data-target="section.key">{{ section.title }}</a>
        </div>
        <span class="edge right">&nbsp;</span>
      </div>
      <LayoutMode/>
    </div>
  </div>
</template>

<script>
  import LayoutMode from 'app/components/_v6/LayoutMode.vue' 

  export default {
    components: {
      LayoutMode,
    },
    
    computed: {
      showJump() {
        if (this.$route.name === 'OneSub' || this.$route.name === 'Topic') {
          return true;
        }

        return false;
      },

      getTopPosition() {
        // something VERY odd on live (only when published) on iOS, in app (safe-areas).. maybe not mutating?
        let header = document.getElementById('header');
        let iHeadHeight = header.clientHeight || 0;
        let sTop = `calc(env(safe-area-inset-top) + ${iHeadHeight}px)`;
        // return an object, with bits that should 'mutate'
        return {
          top: sTop,
          head: iHeadHeight
        };
      },

      getSections() {
        if (this.$route.name == 'Topic') {
          return this.$store.getters.getV6Topic.filter(a => a.title) || [];
        }
        return this.$store.getters.getV6Sections.filter(a => a.title) || [];
      },
    },
    methods: {
      scrollTo(e) {
        let sTo = e.target?.dataset?.target || null;
        if (!sTo) {
          return;
        }
        let eTo = document.querySelector(`section[name="${sTo}"]`);
        if (!eTo) {
          return;
        }
        this.EventBus.$emit('feed:scroll', sTo);
        // eTo.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/v5.scss';
  @import 'src/assets/scss/__2020_v3.scss';

  .section-jump-wrapper {
    // @media (min-width: 750px) {
    //   display: none;
    // }

    height: 3em;

    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: calc(env(safe-area-inset-top) + $header-height);
    left: 0;
    right: 0;
    z-index: 99999;
    padding: 0 1rem;
    // padding: env(safe-area-inset-top) 1rem 0 1rem;
    background: $neutral-600;
    color: $neutral-100;
    border-bottom: 1px solid $neutral-500;

    .darkmode & {
      background: $neutral-100;
      color: $neutral-600;
      border-bottom: 1px solid $neutral-300;
    }

    .inner-section-jump-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1400px;
      margin: auto;
      width: 100%;
    }

    .section-group-wrap {
      position: relative;
      height: 100%;
      z-index: 0;

      width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      margin-right: 1.5rem;
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar { 
          display: none;  /* Safari and Chrome */
      }

      .section-group {
        padding-top: 0.5em;
        display: inline-block;
        position: relative;
      }

      .edge {
        z-index: 20;
        display: inline-block;
        position: sticky;
        width: 1em;
        height: 100%;
        transform: translateY(-0.5em);

        &.left {
          left: 0;
          background: linear-gradient(90deg, rgba($neutral-600, 255), rgba($neutral-600, 0));
          .darkmode & {
            background: linear-gradient(90deg, rgba($neutral-100, 255), rgba($neutral-100, 0));
          }
        }
        &.right {
          right: 0;
          background: linear-gradient(90deg, rgba($neutral-600, 0), rgba($neutral-600, 255));
          .darkmode & {
            background: linear-gradient(90deg, rgba($neutral-100, 0), rgba($neutral-100, 255));
          }
        }
      }
    }

    .section-tag {
      z-index: 10;
      display: inline-block;
      padding: 0.4rem 0.75rem;
      border-radius: 500px;
      background: $neutral-500;
      font-size: 0.8rem;
      font-weight: bold;
      margin-right: 0.5rem;
      transition: all 0.2s ease-in-out;

      &:hover {
        background: $yellow-tone-3;
      }

      .darkmode & {
        background: $neutral-200;
      }

      &:first {
        // margin-left: -.5em;
      }
    }
  }
</style>