import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export default {
  data: function() {
    return {
      raw: {},
    };
  },
  computed: {
    model() {
      return this.$dataEngine.storyGet();
    },
    clusters() {
      return this.$dataEngine.clustersGet();
    },
    articles() {
      return this.model?.articles;
    },
    /**
     *  Basic data..
     */
    image() {
      return this.model.image;
    },
    images() {
      return this.articles?.map(a => a.image);
    },
    photos() {
      return this.articles?.filter(a => a.photo)?.map(a => a.image);
    },
    headline() {
      return this.model.name;
    },
    synopsis() {
      return this.model.synopsis;
    },
    createdAt() {
      return this.model.created_at;
    },
    lastPublishedAt() {
      return this.model.last_published_at;
    },
    topics() {
      return this.model.topics?.filter(a => a.meta.image)?.slice(0,3);
    },
    




    /**
     *  DEFUNCT?? 
     */

    getCurrentStoryIndex() {
      return this.raw?.data?.sections?.findIndex(a => a.slug == this.slug);
    },
    getCurrentStory() {
      return this.raw?.data?.sections?.[this.getCurrentStoryIndex];
    },
    getNextStory() {
      return this.raw?.data?.sections?.[this.getCurrentStoryIndex + 1] || {};
    },
    getPrevStory() {
      return this.raw?.data?.sections?.[this.getCurrentStoryIndex - 1] || {};
    },









    /**
     *  Component display utilities\
     */
    getViewBindingClass() {
      return `view-bind-${this.getViewBinding}`;
    },
    getViewBinding() {
      if (this.levelIsRoot) { return 'root' }
      if (this.levelIsPage) { return 'page' }
      if (this.levelIsDetail) { return 'page'; /*'detail'*/ }
      return 'error-xx';
    },




    /**
     * -------------------------------- START v6 NEW BITS --------------------------------
     */


    getStoryReadState() {
      return this.slug ? this.$store.getters.readState(this.slug) : {};
    },


    card() {
      return this.story?.card || 'card';
    },
    primary () {
      return this.story?.primary ? this.getStory(this.story?.primary) : this.getStory(this.story?.slug);
    },
    others () {
      return this.story?.others?.map(s => {
        if (s == '_skipall') {
          return {
            special: s,
            slug: '',
            name: 'Skip all related stories…',
            image: 'assets/images/skip-all.png',
            class: 'skip-all',
          };
        }
        return this.getStory(s);
      }) || [];
    },

    hipro() {
      let aHighlights = this.$store.getters.getLibraryItem('edition_pro')?.highlight || [];
      let sSlug = this.primary?.slug;

      // console.log(`v6: Pro Highlight '${sSlug}' = `, aHighlights);

      if (!aHighlights?.length || !sSlug) {
        return;
      }
      return aHighlights.includes(sSlug);
    },

    state() {
      // if (!this.$store.getters.getToken) {
      //   if (Math.random() > 0.9) {
      //     return {
      //       code: 'locked'
      //     }
      //   }
      // }
      return this.$store.getters.readState(this.primary.slug) || {};
    },

    getStoriesV6() {

        // slugs: all
        // active: show (now cleaned)
        // clean: remainder

        // wait till we're actually loaded
        if (!this?.section?.slugs?.length) {
          return [];
        }

        // get all the stories, hydrated, sorted by score..
        let aStories = this.section.active
          ?.map(a => this.getStory(a))
          ?.sort((a, b) => {
            return a.score > b.score ? -1 : 1;
          });

        // console.log(`(Clean) - Stories [${this.section.key}] :: `, aStories, this.section);

        if (!aStories?.length) {
          // console.warn(`[getStoriesV6] - Unexpectedly 0 stories? `, this.section);
          return [];
        }

        // return
        let aReturn = [];

        // build 'related stories' -- which is pretty shanty


        // get some stats on cleanings.. 
        // let iCleanLogBase = this.$store.getters.getV6CleanLogItem(this?.section.key);
        // console.log(`Clean Baseline [${this.section.key}]: ${iCleanLogBase}`);


        // go through every story .. and calc the XO with every other story..
        aStories.forEach(aOuter => {
          aOuter.xo = [];
          aStories.forEach(aInner => {
            aOuter.xo.push(this.v6_calcXO(aInner.topics, aOuter.topics));
          });
          aOuter.xo = this.v6_getAvgStd(aOuter.xo);
          // console.log(`XO Map: ${aOuter.slug} : `, aOuter.xo);
        })




        // go through every story
        // starting with a blank "return" (ie. selected) list... 
        aStories.forEach((aStory) => {
          let aLocalTopics = aStory.topics;
          let bMatched = false;

          // look for a decent crossover (do any of the *selected/primary* (ie, none to begin with)) match well?) ....
          aReturn.some((aChunk) => {
            // check this aReturn item hasn't been cleaned!
            // if (iCleanLogBase) {
            //   return;
            // }

            let iXO = this.v6_calcXO(aChunk.topics, aLocalTopics);
            let bMatch = (iXO >= (aStory.xo.avg + (aStory.xo.std * 0.85)));
            if (bMatch) { // was 1.  Massively arbitrary... could actually use some kMeans :o)
              // .... and if there is a match, add this story onto the *selected/primary* story's 'other' list..
              // console.log(`iXO: ${iXO} > ${aStory.xo.avg}`, aStory.name);
              aChunk.others.push(aStory.slug);
              bMatched = true;
              return true;
            }
          });

          // in there was no match (always the case on the first item) then stack this story onto the primary stack... 
          if (!bMatched) {
            // create a new chunk
            aReturn.push({
              topics: aStory.topics,
              primary: aStory.slug,
              others: [],
            });
          }
        });



        // okay - let's look again.  if there are any stories + with other/related stories + where the story is marked "read/skipped/etc" then offer "skip all related"
        aReturn.forEach(oStory => {
          let sSlug = oStory.primary;
          let oState = this.$userEngine.readState(sSlug);
          let bSomeUnSkipped = oStory.others.some(sSlug => {
            let oOtherState = this.$userEngine.readState(sSlug);
            // valid, displaying story (new or updated)
            return (!oOtherState?.code || oOtherState.updated);
          });

          // primary = read/skipped/etc + there are two-or-more 'others' + we haven't *already* skipped them all 
          if (oState.code && (oStory.others.length >= 2) && bSomeUnSkipped) {
            // console.log(`Primary ${sSlug} has others. Offering "SkipAll" `, oState);
            // expects a slug really..
            oStory.others.unshift('_skipall');
          }
        });


        // consider the score as a proxy for how important the story is.
        let avgdev = this.v6_getAvgStd(aStories.map(a => a.score));
        
        // try and set some style
        let oCX = {};
        let oLimits = {
          top: {
            max: 1,
            next: 'major',
          },
          major: {
            max: 2,
            next: 'card',
          },
        };


        aReturn.forEach(a => {
          let aStory = this.getStory(a.primary);
          if (aStory?.score > (avgdev.avg + (avgdev.std / 2))) {
            a.card = (a.others.length >= 2) ? 'top' : 'major';
          } else if (aStory?.score < (avgdev.avg - (avgdev.std / 2))) {
            a.card = a.others.length ? 'feature' : 'minor';
          } else {
            a.card = 'card';
          }
          
          // limit (should really do{}while)
          if (oCX[a.card] >= oLimits[a.card]?.max) {
            a.card = oLimits[a.card]?.next;
          }

          // count stats
          oCX[a.card] = oCX[a.card] ? ++oCX[a.card] : 1;
        });

       
        // only one?
        if (aReturn.length == 1) {
          aReturn[0].card = 'top';
        }

        // console.log(`\x1b[33mgetStoriesV6(): `, aReturn, avgdev, oCX);
        
        return aReturn; 
    },


    /**
     * -------------------------------- END v6 NEW BITS --------------------------------
     */

  },
  methods: {
    /**
     * -------------------------------- START v6 NEW BITS --------------------------------
     */

    haptic(note) {
      if (!['success', 'error', 'warning'].includes(note)) {
        console.error(`Invalid haptic note: ${note}`);
        return;
      }
      this.EventBus.$emit("device:haptic", note);
    },

    v6_calcXO(a, b) {

      let iScore = 0;
      let aBase = [];
      let aComp = [];
      if ( a > b ) {
        aBase = a;
        aComp = b;
      } else {
        aBase = b;
        aComp = a;
      }

      aBase?.forEach(tBase => {
        aComp?.some(tComp => {
          if (tBase.topic_code == tComp.topic_code) {
            // accumulate the average score
            iScore += ((tBase.score + tComp.score) / 2);
            // and short circuit to the next base topic
            return true;
          }
        });
      });
      // console.log(`Match: ${iScore}`);
      return iScore;
    },
    v6_getAvgStd (scores) {
      let len = scores.length;
      let avg = scores.reduce((a, b) => a + b) / len;
      let std = Math.sqrt(scores.map(x => Math.pow(x - avg, 2)).reduce((a, b) => a + b) / len);
      return {
        len: len,
        avg: +avg.toFixed(2),
        std: +std.toFixed(2),
      };
    },





    /**
     * -------------------------------- END v6 NEW BITS --------------------------------
     */



    timeAgo(date) {
      return dayjs().to(dayjs(date));
    },

    clusterReadState(i) {
      return this.$userEngine.readState(this.clusterArticle(i).slug);
    },
    clusterIsExpanded(i) {
      // DEPRECATED
      return this.$dataEngine.clusterIsExpanded(i);
    },
    clusterIsFocused(i){
      // AS ABOVE - but betterly namedified.
      return this.$dataEngine.clusterIsExpanded(i);
    },
    clusterWhen(i) {
      return this.timeAgo(this.$dataEngine.clusterDate(i));
    },
    clusterDate(i) {
      return this.$dataEngine.clusterDate(i);
    },
    clusterUpdateStatement(i) {
      return this.$dataEngine.clusterUpdateStatement(i);
    },
    clusterHeadline(i) {
      return this.$dataEngine.clusterHeadline(i);
    },
    clusterSynopsis(i) {
      return this.$dataEngine.clusterSynopsis(i);
    },
    clusterPublishers(i) {
      return this.$dataEngine.clusterPublishers(i);
    },
    clusterSlug(i) {
      return this.$dataEngine.clusterSlug(i);
    },
    clusterArticle(i) {
      return this.$dataEngine.clusterArticle(i);
    },
    clusterTopics(i) {
      return this.$dataEngine.clusterTopics(i);
    },
    clusterStateClass(i) {
      return this.clusterIsExpanded(i) ? 'state-expanded' : 'state-inactive'; // state-active
    },

    getArticle(slug) {
      // console.log(slug);
      // console.log(this.model?.articles.map(o => o.slug));
      return this.model?.articles?.find(o => o.slug == slug) || {};
    },
    getNodePath(node, slug) {
      slug = slug || this.$route.params.slug;
      // TRANSITION: while v3 & v5 live toghether, /story isn't a given..
      let sBase = this.$route.fullPath.replace(/^\//,'').split('/')[0];
      // return `/v3/story/${slug}/${node}`;


      // DON'T LOSE THE QUERY STRING!
      let sQuery = Object.keys(this.$route.query).length ? '?' + new URLSearchParams(this.$route.query).toString() : '';

      // and return..
      return `/${sBase}/${slug}/${node}${sQuery}` ;
    },


    isCurrent(slug) {
      // return slug == this.getCurrentStory?.slug;
      return this.getCurrentStory?.slug == slug;
    },
    isNext(slug) {
      return slug == this.getNextStory?.slug;
    },
    isPrev(slug) {
      return slug == this.getPrevStory?.slug;
    },






    /**
     *  Utilities - From refactor of Nav > Group > Story
     *  ------------------------------------------------ 
     */

    // NB: method, not computed
    getReadState(slug) {
      return this.$store.getters.readState(slug);
    },

    getStory(storySlug) {
      return this.$store.getters.getLibraryItem('story_stems_v3', storySlug) || {
        _error: `Failed to load: ${storySlug}`,
      };
    },

    getTopic(topicSlug) {
      return this.$store.getters.getLibraryItem('topics_v3', topicSlug) || {
        meta: {},
      };
    },

    getTopicGroup(topicGroupSlug) {
      // console.log('topicGroupSlug: ', topicGroupSlug);
      return topicGroupSlug?.split(':').map(s => this.getTopic(s));
    },
  }
}