import Vue from 'vue';
// import API from 'app/axios';
import store from "./store";
import router from './router';
import utils from '@assets/js/utils.js';
// import { timeHours } from 'd3';

/**
 *  Ascii
 *  https://patorjk.com/software/taag/#p=display&h=2&v=3&c=c%2B%2B&w=%20&f=ANSI%20Regular&t=Topic
 */

const dataEngine = new Vue({
  mixins: [utils],

  router,
  store,

	data: function () {
		return {
      // v6
      aScored: [], // record of slugs seen during prep (recorded by scoring)
      // ------- ends v6



      prepared: {},
      unreadSections: [], // prepared sections, personalised & filtered
      unreadStories: [], // a full list of stories from all sections
      readHideAfter: 60 * 6, // 3 hours


      // FROM: Node.vue -- deployed more generally for v4 components...
      templates: {
        attr: {
          _default: () => `${this.p.sSenX}.`,
          first: {
            pool: () => `${this.p.sSenX}, according to ${this.p.sPubMark}.`,
            assumed: () => `${this.p.sSenX}, writes ${this.p.oAuthor.fname} ${this.p.oAuthor.sname} for ${this.p.sPubMark}.`,
            individual: () => `${this.p.sSenX}, writes ${this.p.oAuthor.fname} ${this.p.oAuthor.sname} for ${this.p.sPubMark}.`,
          },
          nth: {
            pool: () => `According to ${this.p.sPubMark}, ${this.p.sSenXlc}.`,
            assumed: () => `Writing for ${this.p.sPubMark}, ${this.p.oAuthor.fname} ${this.p.oAuthor.sname} said ${this.p.sSenXlc}.`,
            individual: () => `Writing for ${this.p.sPubMark}, ${this.p.oAuthor.fname} ${this.p.oAuthor.sname} said ${this.p.sSenXlc}.`,
          },
        }
      },
      p: {}, // legacy (early proto) for storing compiled content derived from the API data.. 
      
      // v5Home -- dedupe stacks
      v5Dedupe: {
        stories: {},
        locales: {},
        topics: {},
      },
      v5Thresholds: {
        dedupe: {
          stories: 0.3, // [0.3 > kept]
          locales: 50, // [50 > kept]
          topics: 50, // [50 > kept]
          addscore: 1, // muliplier for re-adding stories
        },
        readstate: {
          stories: 0.20, // [.25 > .20] (// <<-- critical)
          locales: 0.20, // [1.0 > .20]
          topics: 0.20, // [1.0 > .20]
        }
      },
      v5PreBuiltSections: [],
		}
	},

	created() {
    // console.log("DataEngine - Instantiated");
  },

  watch: {
    // rawEdition(to, from) { // Copy as had to remove (to, from) to avoid errors
    rawEdition() {
      // console.log(`\x1B[33m DataEngine - EDITION CHANGE: `, from, to);
      // this.parseEdition();
      this.v6_getSections();
    },
    rawEditionV6() {
      // console.log(`\x1B[33m DataEngine - EDITION CHANGE: `, from, to);
      // this.parseEdition();
      this.v6_getSections();
    },
    rawReadState() {
      // console.log(`\x1B[33m DataEngine - READ STATE CHANGE: `, from, to);
      // this.parseEdition();
      this.v6_getSections();
    },
    rawCleanLog() {
      // console.log(`\x1B[33m DataEngine - CLEAN LOG CHANGE: `, from, to);
      // this.parseEdition();
      this.v6_getSections();
    },
    rawTopic() {
      this.v6_getSections();
    }
  },
  
  computed: {

    rawTopic() {
      if (this.$route.name == 'Topic') {
        return this.$store.getters.getLibraryItem('topics', this.$route.params?.topic);
      }
      return {};
    },

    // need to think about topic pages too :/
    rawEdition() {
      return [
        this.$store.getters.getLibraryItem('edition_v6'),
        this.$store.getters.getLibraryItem('edition_pro')
      ];
    },
    rawEditionV6() {
      return [
        this.$store.getters.getLibraryItem('edition_v6'),
        this.$store.getters.getLibraryItem('edition_pro'),
      ];
    },
    rawReadState() {
      return this.$store.getters.readStateAll;
    },
    rawCleanLog() {
      // return this.$userEngine?.getPref('layout.feed.cleanlog');
      // return this.$userEngine?.prefs?.layout?.feed?.cleanlog;
      return this.$store.getters.getSettings?.layout?.feed?.cleanlog;
      // return this.$store.getters.getV6CleanLog;
    },
    rawStories() {
      return this.rawEdition.stories;
    },
    editionMeta() {
      return this.rawEdition.meta || {};
    },


    // public getters for stories & sections
    getSections() {
      return this.unreadSections || [];
    },

    slug() {
      return this.$route.params.slug;
    },
    node() {
      return this.$route.params.node;
    },

    nextStory() {
      // only possible if we have some data
      if (!this.rawStories?.length) {
        return {};
      } 

      let iSlug = this.rawStories?.findIndex(a => a.slug == this.slug);
      // // console.log(`\x1B[33m DataEngine - NEXT - Find Index: ${this.slug} (:${iSlug})`, this.rawStories.map(a => a.slug));

      // if we can find the slug.. find the next item that is unread
      let oNextStory = false;
      if (iSlug >= 0) {
        oNextStory = this.rawStories?.slice(iSlug + 1).find(a => {
          // console.log(`\x1B[33m DataEngine - NEXT - Find Next: ${this.slug} (:${iSlug})`, a.slug, this.readStory(a.slug), this.rawStories.map(a => a.slug));
          return this.readStory(a.slug)
        });
      }
      // // console.log(`\x1B[33m DataEngine - NEXT - Respond: ${this.slug} (:${iSlug})`, oNextStory);

      return oNextStory;
    },

    nextBySection() {
      if (!this.rawStories?.length) {
        return {};
      }
      let aReturnList = [];

      this.getSections?.forEach(section => {
        let aSeen = {};
        [
          {code: 'themes', type: 'object'},
          {code: 'notable', type: 'object'},
          {code: 'remain', type: 'array'},
        ].forEach(oMap => {
          // console.log("Next: ", section, oMap);
          let aSlugs = oMap.type == 'object' ? Object.values(section.layout.topic_map[oMap.code]).flatMap(slug => slug) : section.layout.topic_map[oMap.code];
          aSlugs?.forEach(slug => {
            // console.log(`Next: ${section.code}:${sMapKey} = ${slug}`);
            // Ignore things we've seen (the homepage dedupes as it goes)
            if (aSeen[slug]) {
              return;
            }
            // otherwise stack
            aSeen[slug] = true;
            aReturnList.push(slug);
          });
        });
      });

      // given an ordered list with known read-states
      // console.log("Next: Output List -- ", aReturnList);
      // console.log("Next: ==== ", );

      let sNext = this.nextUnreadFromObjectList(aReturnList, this.slug);

      return this.rawStories.find(a => a.slug == sNext);
    },

    nextByScore() {
      if (!this.rawStories?.length) {
        return {};
      }

      let rawByScore = this.rawStories.map(a => {
        return {
          slug: a.slug,
          score: a.score,
        };
      })
      .sort((a, b) => a.score > b.score ? -1 : 1)
      .map(a => a.slug);

      let sNext = this.nextUnreadFromObjectList(rawByScore);
      return this.rawStories.find(a => a.slug == sNext);
    },
  },

	methods: {


    /**
     *  ---------------------------------------------------------------
     *  v6 Services -- Compiles on watcher when /edition loaded?
     *  _______________________________________________________________
     */

    v6_resetProEdition() {
      this.$store.dispatch('resetProEdition');
    },

    v6_getSections() {
      // our output object (keyed, for the markup)
      let oOutput = [];
      let oEdition = {};
      let sStorageSlug = '';
      let oSettings = {
        mode: 'home',
        banners: true,
        three: true,
      };

      /**
       *  ======================
       *  What sort of section!? Edition, Topic of Search?
       *  ======================
       */

      if (this.$route.name == 'Topic') {

        sStorageSlug = 'setV6Topic';
        oSettings = {
          mode: 'topic',
          banners: false,
          three: false,
        };

        // =============== TOPIC
        oEdition = this.$store.getters.getLibraryItem('topics', this.$route.params?.topic) || {};

      } else {

        // =============== EDITION (Standard /homepage)
        sStorageSlug = 'setV6Sections';
        

        let oRegion = this.$store.getters.getLibraryItem('edition_v6') || {};
        let oPro = this.$store.getters.getLibraryItem('edition_pro') || {};

        // now, deep copy the region edition, just in case we want it in one piece
        oEdition = {...oRegion};

        if (oPro.sections) {
          oEdition.sections = [...(oPro.sections || []), ...(oEdition.sections || [])];
          oEdition.stories = [...(oPro.stories || []), ...(oEdition.stories || [])];
          oEdition.topics = [...(oPro.topics || []), ...(oEdition.topics || [])];
        }

      }











      // bit of debugging..
      // let iRegion = oRegion?.sections?.length;
      // let iPro = oPro?.sections?.length;
      // console.log(`\x1b[33m[getSections] - v6 Build: - Source Editions (Region: ${iRegion}, Pro: ${iPro})`);
      // console.log(`\x1b[33m[getSections] - v6 Build: - Source Sections (${sStorageSlug}): `, oEdition?.sections?.map(a => a.code), {...oEdition});

      // go through the /edition endpoint
      oEdition?.sections?.forEach((section) => {
        // define for use below...
        let sGeograpy = section.code; // domestic/foreign (fix: -breaking)
        let sKey = `${sGeograpy}:_error`;
        let bPro = section.meta?.args?.pro || false;

        
        if (bPro) {
          console.log(`\x1b[33m[getSections] - v6 Build: - ${bPro}: `, section);
          sKey = `${sGeograpy}:pro`;
          // stack
          oOutput[sKey] = {
            key: sKey,
            topic: section.name || "Pro Stories",
            mode: sGeograpy,
            group: sKey,
            score: {
              score: 999,
              cx: section.stories.length,
              avg: 999,
              raw: 999,
              skew: 0,
            },
            slugs: section.stories,
            active: this.doCleanUp(sKey, section.stories, true),
            clean: this.doCleanUp(sKey, section.stories, false),
            meta: section.meta,
          }
          return;
        }

        // stack the "notable" items on as whole sections
        Object.entries(section?.layout?.topic_map?.notable).forEach(([kTopic, oTopic]) => {
          // build a markup-key
          sKey = `${sGeograpy}:${kTopic}`;
          // stack
          oOutput[sKey] = {
            key: sKey,
            topic: kTopic,
            mode: sGeograpy,
            group: kTopic,
            score: this.scoreSlugs(oTopic, oEdition, section.code, kTopic),
            slugs: oTopic,
            active: this.doCleanUp(sKey, oTopic, true),
            clean: this.doCleanUp(sKey, oTopic, false),
            meta: section.meta,
          }
        });

        // now stack the "singles" (other groups of topics)
        let oSingles = section?.layout?.topic_map?.singles; // Object.entries(section?.layout?.topic_map?.singles).slice(0,99);
        sKey = `${sGeograpy}:singles`;
        // console.log(`\x1b[33m[getSections] - Topic Singles - `, oSingles);
        let aAllSlugs = Object.values(oSingles)?.reduce((a, c) => { return [...a, ...c]; }, []);
        

        if (sGeograpy != 'foreign') {

          // stack
          oOutput[sKey] = {
            key: sKey,
            topic: '_topics',
            mode: sGeograpy,
            group: 'singles',
            score: this.scoreSlugs(aAllSlugs,oEdition, section.code, 'singles'),
            meta: section.meta,
            // slugs: aSlugs,
            data: oSingles,
            // need consistency tho (for cleaning, charting etc.)
            slugs: aAllSlugs,
            active: this.doCleanUp(sKey, aAllSlugs, true),
            clean: this.doCleanUp(sKey, aAllSlugs, false),
          }

        } else {
          // foreign: let's look at the countries..
          let oFiltered = {};
          // remove any stories we've already seen
          Object.entries(section?.layout?.locale_map).forEach(([kLocale, aSlugs]) => {
            // filter any slugs that have already been seen (scored)
            aSlugs = aSlugs.filter(s => !this.aScored[s]);
            // ignore those we've filtered completely
            if (!aSlugs?.length) {
              return;
            }
            // create a new object
            oFiltered[kLocale] = aSlugs;
          });
          // console.log(`\x1b[33m[getSections] - Filtered Locales - `, oFiltered, Object.values(oFiltered));
          // re-map
          let aAllSlugs = Object.values(oFiltered).reduce((a, c) => { return [...a, ...c]; }, []);
          // stack
          oOutput[sKey] = {
            key: sKey,
            topic: '_locales',
            mode: sGeograpy,
            // group: section.code,
            group: 'singles',
            score: this.scoreSlugs(aAllSlugs, oEdition, section.code, 'singles'),
            meta: section.meta,
            // slugs: aSlugs,
            data: oFiltered,
            // need consistency tho (for cleaning, charting etc.)
            slugs: aAllSlugs,
            active: this.doCleanUp(sKey, aAllSlugs, true),
            clean: this.doCleanUp(sKey, aAllSlugs, false),
          }
        }

      });

      // console.log(`\x1b[33m[getSections] - v6 Build: - OUTPUT: `, oOutput);

      // return oOutput;
      let aOutput = Object.values(oOutput).sort((a, b) => {
        // console.log(`${a.score.score} < ${b.score.score}`);
        return +a.score.score > +b.score.score ? -1 : 1;
      });

      // now plan the templates
      let oFeatures = {};
      aOutput.map(oSection => {
        
        if (oSection.group == 'singles') {
          oSection.component = 'columns';
          return oSection;
        }

        if (!oFeatures[oSection.mode]) {
          oFeatures[oSection.mode] = true;
          oSection.component = 'feature';
          return oSection;
        }

        oSection.component = 'plain';
        return oSection;
      });

      // return aOutput;

      // return some abberations (actually, probably "breaking") // oops.. fix this!
      aOutput = aOutput.filter(a => a?.score?.cx);




      /**
       * Add titles
       */

      aOutput.map(section => {
        let aTitle = [];
        
        // foreign or domestic?
        if (section.mode == 'foreign') {
          aTitle.push('Global');
        }

        // topic?
        if (section.topic == '_locales') {
          aTitle = ['Global Stories'];
        } else if (section.topic == '_topics') {
          aTitle = ['More Stories'];
        } else {
          if (section.topic == 'XXXX___politics') { // disabled for now
            aTitle.push('Headlines');
          } else {
            aTitle.push(this.$options.filters.ucwords(section.topic));
          }
        }

        section.title = aTitle.join(' ');

        return section;
      })






      // do NOT start adding banners while there are no real cards/sections! FOUC!
      // console.log(`\x1b[33m[getSections] - v6 Build: (${sStorageSlug}) - Section Length: `, aOutput.length);
      if (!aOutput.length) {
        return;
      }









      /**
       * PRO SIGNUP - PRO SIGNUP - PRO SIGNUP - PRO SIGNUP - PRO SIGNUP - PRO SIGNUP - PRO SIGNUP 
       */

  

      // add the 'go-pro' tools..
      // aOutput.unshift();
      if (this.isAlpha) {
        let oAccount = this.$store.getters.getAccount;
        let oPro = oAccount?.pro || {};

        let oProTools = {
          key: 'pro-tools',
          component: 'pro-tools',
          pro: oPro,
        };

        if (!oPro.pattern) {
          aOutput.splice(1, 0, oProTools);
        }
      }















      /**
       * Banners - Banners - Banners - Banners - Banners - Banners - Banners - Banners
       */



      // @@JASON -- Hack in the new stuff we're working on..
      
      // Add banners - @JIM - Needs some logic to determine which banner + to repeat between sections where we want them?
      let bSignUp = {
        key: 'banner',
        component: 'banner',
        payload: {
          state: 'sign-up',
          byline: 'Get a better news diet.'
        }
      };
      let bGoPro = {
        key: 'banner',
        component: 'banner',
        payload: {
          state: 'go-pro',
          byline: 'Tune your news feed.'
        }
      };

      // // guest: sign-up!
      if (!this.$store.getters.isLoggedIn) {
        aOutput.splice(1, 0, {...bSignUp, ...{key: 'banner-start'}});
        aOutput.splice(4, 0, {...bSignUp, ...{key: 'banner-near'}});
        aOutput.splice(-3, 0, {...bSignUp, ...{key: 'banner-far'}});
        aOutput.push({...bSignUp, ...{key: 'banner-end'}});
      }

      // guest: sign-up!
      if (this.$store.getters.isLoggedIn && !this.$store.getters.isPro) {
        aOutput.splice(1, 0, {...bGoPro, ...{key: 'banner-start'}});
        aOutput.splice(4, 0, {...bGoPro, ...{key: 'banner-near'}});
        aOutput.splice(-3, 0, {...bGoPro, ...{key: 'banner-far'}});
        aOutput.push({...bGoPro, ...{key: 'banner-end'}});
      }

     


      // is this a page that accepts banners (ie. home, not topic)
      if (oSettings.banners) {
        
        let oPromo = {};

        const hasHiddenNourishBanner = localStorage.getItem('banner:nourish');
        if (!hasHiddenNourishBanner) {
          oPromo = {
            key: `promo-norish-namechange`,
            component: 'promo',
            payload: {
              title: `We're changing…`,
              content: `Discover how we help you take control of your news diet.`,
              video: 'https://player.vimeo.com/video/951998215',
              cta: `Join Nourish for Free`,
              action: 'modal:auth',
              options: 'sign-up',
            },
          };
        }
        
        // splice in at the top..
        aOutput.splice(0, 0, oPromo);

      }


      // // +add: three things
      // if (this.$route.query.beta && oSettings.three) {
        
      //   // obs would be more circumspect in the end!
      //   let aThree = oEdition.stories?.slice(0, 3);
      //   console.log(`Three: `, aThree);

      //   let oThree = {
      //     key: `three-things`,
      //     component: 'top-three',
      //     three: aThree,
      //     title: 'Three things to start your day.',
      //   };
        
      //   // splice in at the top..
      //   aOutput.splice(0, 0, oThree);
      // }

      // // +add: guest banner
      // if (this.$route.query.beta && oSettings.banners) {
        
      //   let oPromo = {};
      //   let sPromo = `unique-foo`;

      //   const hasHiddenNourishBanner = localStorage.getItem('banner:nourish');
      //   if (!hasHiddenNourishBanner) {
      //     oPromo = {
      //       key: `promo-${sPromo}`,
      //       component: 'promo',
      //       promo: {
      //         foo: 'bar',
      //         title: 'What is OneSub',
      //         video: 'vimeo:1235', // etc..
      //       },
      //     };
      //   }
        
      //   // splice in at the top..
      //   aOutput.splice(0, 0, oPromo);


      //   oPromo = {
      //     key: `dash-streak`,
      //     component: 'promo',
      //     promo: {
      //       foo: 'bar',
      //       title: 'My Streak',
      //       unit: 'dash:streak', // etc..
      //     },
      //   };
        
      //   // splice in the middle ..
      //   aOutput.splice(3, 0, oPromo);



      //   oPromo = {
      //     key: `end-card`,
      //     component: 'promo',
      //     promo: {
      //       foo: 'bar',
      //       title: 'All done',
      //       unit: 'end-card', // etc..
      //     },
      //   };
        
      //   // splice in at the end ..
      //   aOutput.push(oPromo);




      // }








      // console.log(`\x1b[33m[getSections] - v6 Build: (${sStorageSlug}) - `, aOutput);
      this.$store.commit(sStorageSlug, aOutput);
    },


    scoreSlugs(aSlugs, edition, section, group) {
      let oDomainSkew = {
        'domestic': 2.0, 
        'domestic-emerging': 2.5, 
        'foreign': 1.5, 
        'foreign-emerging': 1.75, 
      };
      let oGroupSkew = {
        'other': 0.1, 
        'singles': 0.25, 
      };

      let iSkew = (oDomainSkew[section] || 1) * (oGroupSkew[group] || 1);
      let iCount = aSlugs?.length;
      let iTotal = aSlugs?.map(sSlug => {
        // record that we've scored it, for filtering later
        this.aScored[sSlug] = true;
        // return the score
        return edition?.stories?.find(o => o.slug == sSlug)?.score;
      }).reduce((a, c) => { return a + c; }, 0);
      
      let iPrecision = 0;
      return {
        score: (iTotal * iSkew).toFixed(iPrecision),
        cx: iCount,
        avg: +((iTotal * iSkew) / iCount).toFixed(iPrecision),
        raw: +(iTotal).toFixed(iPrecision),
        total: iTotal,
        skew: iSkew,
      };
    },

    doCleanUp(sKey, aSlugs, bActive) {
      // let sThreshold = this.$store.getters.getV6CleanLogItem(sKey);
      let sThreshold = (this.$userEngine.getPref(`layout.feed.cleanlog`) || {})[sKey];
      
      // no clean-up threshold ... fine, return all active, none cleaned
      if (!sThreshold) {
        // console.log(`doCleanUp: ${sKey}:* -- No Threshold Recorded`);
        return bActive ? aSlugs : []; 
      }

      // console.log(`doCleanUp: ${sKey}:* -- Threshold: ${sThreshold}`);
      let iThreshold = (new Date(sThreshold)).getTime();

      let bDebug = bActive && ['foreign:international relations'].includes(sKey) && false;
      
      // active or inactive?
      let aReturn = aSlugs.filter(slug => {
        if (bDebug) {
          console.log(`${slug} -- `, this.state(slug), sThreshold);
        }
        // consider a bit of a "updated 5 mins after I read it" threshold here.  unread shows number of days..
        // okay, for now... anything that's more than a day over being updated since you last read... 
        let sStateStamp = (this.state(slug)?.unread) ? false : (this.state(slug)?.updated || this.state(slug)?.date);

        // no recorded state
        if (!sStateStamp) {
          // keep for active : don't mark 'cleaned'
          if (bDebug) {
            console.log(`doCleanUp: ${sKey}:${slug} -- no stamp`);
          }
          return bActive ? true : false;
        }

        // found a state-stamp
        let iStateStamp = new Date(sStateStamp).getTime();
        let bInPast = iStateStamp < iThreshold;

        // stamps BEFORE cleanup are NOT active / ARE cleaned
        if (bDebug) {
          console.log(`doCleanUp: ${sKey}:${slug} -- stamp in past?: ${bInPast} (${iStateStamp} < ${iThreshold})`);
        }
        return bActive ? !bInPast : bInPast;
      });

      if (bDebug) {
        console.log(`doCleanUp: ${sKey}:(bActive:${bActive}) == `, aReturn);
      }
      return aReturn;
    },

    state(slug) {
      return this.$store.getters.readState(slug) ||  {};
    },






























    rawBySlug(sSlug) {
      // console.log(sSlug, this.rawStories);
      return this.rawStories?.find(a => a.slug == sSlug) || {};
    },



    /**
     *  ---------------------------------------------------------------
     *  v3-5 Content Body
     *  _______________________________________________________________
     */
    parasToArray(sNodes) {
      let d = document.createElement('div');
          d.innerHTML = sNodes;
      return Array.from(d.querySelectorAll('p'));
    },

    v3_buildSynthBody(sStory, sNode) {
      // console.log(`[Synth] [${sStory}, ${sNode}] :: Starting...`);
      // wait until we actually have a node..
      if (!sNode) {
        return [];
      }

      let oNodeContent = this.$store.getters.getStoryNodeV3(sNode);
      let oStory =  this.$store.getters.getStoryV3(sStory);
      // let aFocus = oNodeContent?._debug?.articles?.focus || {};
      let aStack = [];

      // wait until we have some node content
      if (!oNodeContent.node) {
        return [];
      }

      // get the articles of the specific node
      let oNodeBlock = oStory?.clusters?.timeseries.filter(a => a?.links.includes(sNode)).shift();
      let aNodeArticles = oStory.articles.filter(a => oNodeBlock?.links?.includes(a.slug));
      // console.log(`\x1b[34m[v3Body] - Node Block: `, oNodeBlock);
      // console.log(`\x1b[34m[v3Body] - Node Articles: `, aNodeArticles);
      // console.log(`\x1b[34m[v3Body] - Node Content: `, oNodeContent);


      // do we have a better synth article?
      if (oNodeContent?.synthetic?.cache?.synopsis) {
        // let sample = oNodeContent?._debug?.articles?.articles?.find(o => o.slug == aFocus['med']?.article) || {};
        
        // prefer a photo from the right cluster!
        let sample = aNodeArticles?.find(o => o.photo) || oStory.articles?.find(o => o.photo) || {};
        

        // add the synopsis.. 
        // this.parasToArray(oNodeContent?.synthetic?.cache?.synopsis).forEach((para) => {
        //   aStack.push({
        //     copy: para.innerText,
        //     image: sample?.image,
        //     photo: sample?.photo,
        //   });
        // });

        // add the body.. 
        aStack.push({
          copy: oNodeContent?.synthetic?.cache?.synopsis,
          caption: `&ldquo;${sample.name}&rdquo; <span class="photo-credit">Photograph: ${sample.publisher.name}</span>`,
          image: sample?.image,
          photo: sample?.photo,
        });

        // if (oNodeContent?.synthetic?.cache?.events?.length) {
        //   aStack.push({
        //     template: 'timeline',
        //     subhead: "What's happened",
        //     timeline: oNodeContent?.synthetic?.cache?.events,
        //   });
        // } else if (oNodeContent?.synthetic?.cache?.event?.length) {
        //   aStack.push({
        //     subhead: "What's happened",
        //     copy: oNodeContent?.synthetic?.cache?.event,
        //   });
        // }

        // aStack.push({
        //   // subhead: "Why it matters.",
        //   copy: oNodeContent?.synthetic?.cache?.relevance,
        // });

        if (oNodeContent?.synthetic?.cache?.change) {
          aStack.push({
            subhead: "What's changed.",
            copy: oNodeContent?.synthetic?.cache?.change,
          });
        }

        aStack.push({
          subhead: "How we got here.",
          copy: oNodeContent?.synthetic?.cache?.background,
        });

        if (oNodeContent?.synthetic?.cache?.analysis) {
          oNodeContent?.synthetic?.cache?.analysis?.forEach(a => {
            aStack.push({
              subhead: a.title,
              copy: a.body,
              wrap: 'div',
            });
          });
        }

        // if (oNodeContent?.synthetic?.cache?.gas) {
        //   aStack.push({
        //     subhead: "Should I care?",
        //     copy: oNodeContent?.synthetic?.cache?.gas,
        //   });
        // }
        
        // aStack.push({
        //   subhead: "Why it matters.",
        //   copy: oNodeContent?.synthetic?.cache?.whatif,
        // });

        aStack.push({
          subhead: "Reasons to be positive.",
          copy: oNodeContent?.synthetic?.cache?.hero,
        });


        aStack.push({
          subhead: "What the papers say.",
          copy: this.parseInLinks(oNodeContent?.synthetic?.cache?.sources, {story: oStory}),
        });


        if (oNodeContent?.synthetic?.cache?.followups?.length) {
          aStack.push({
            subhead: "Go deeper.",
            followups: oNodeContent?.synthetic?.cache?.followups || [],
          });
        }
        


        // if (!this.$store.getters.isLoggedIn) {
        //   aStack.splice(4, 0, {
        //     banner: 'sign-up',
        //   });
        // }



        // console.log("[Synth] -- New! :", aStack, aFocus);
        return aStack;
      }
















      // console.log(`[Synth] [${sNode}] :: `, this.$store.getters.getStoryNodeV3(sNode));

      // if (aFocus) {
      //   ['intro','med', 'neg', 'pos'].forEach(k => {
      //     // oftimes missing:
      //     if (!aFocus[k]) return;

      //     // grab the article:

      //     // this.p.oArticle = this.getArticle(aFocus[k].article);
      //     this.p.sArticle = aFocus[k].article;
      //     this.p.oArticle = oNodeContent?._debug?.articles?.articles?.find(o => o.slug == this.p.sArticle) || {};
      //     this.p.oAuthor = this.p.oArticle.author;

      //     // console.log(`[Synth] [${sNode}] :: Article - `, this.p.oArticle);
      //     // indi or pool?

      //     // display: inline-block; width: 18px; height: 18px;
      //     // width: 100%; height: 100%; object-fit: cover; border-radius: 50%;
      //     // markers

      //     this.p.sErrorLinks = '';
      //     // add error-reporting
      //     if (this.isAlpha) {
      //       this.p.sErrorLinks = `
      //       <br/>
      //       <span class="pub-errors">
      //         (<span data-click="report" data-article="${this.p.oArticle.slug}" data-type="body">BODY</span> |
      //         <span data-click="report" data-article="${this.p.oArticle.slug}" data-type="author">AUTHOR</span> |
      //         ${this.p.oArticle.slug})
      //       </span>
      //       `;
      //     }

      //     let sIconSrc = this.p.oArticle.publisher.icon;
      //     this.p.sPubIcon = (sIconSrc && (sIconSrc != '(unknown)')) ? `<img src="${sIconSrc}">` : '';
      //     this.p.sPubLink = `<a data-click="outclick" data-article="${this.p.oArticle.slug}">${this.p.oArticle.publisher.name}</a>`;
      //     this.p.sPubMark = `<span class="pub-mark">${this.p.sPubIcon} ${this.p.sPubLink}</span>`;

      //     // o.text[0].toLowerCase() + o.text.slice(1);
      //     this.p.sPubMarkPlacement = this.templates.attr[aStack.length? 'nth' : 'first' ]?.[this.p.oAuthor.type] || this.templates.attr['_default']; 
          

      //     let bPubNth = false;

      //     // add the sentences:
      //     Object.values(aFocus[k]?.selection)?.forEach(o => {
      //       this.p.sSenX = o?.text?.replace(/\.$/,'').trim();
      //       if (!this.p.sSenX) return;
            
      //       // what's the first word?
      //       this.p.sSenXFirst = this.p.sSenX.trim().replace(/\s.*/,'').trim();

      //       // lowercase any stopwords as first word (hopefully most things but proper nouns)
      //       this.p.sSenXlc = this.$store.getters.isStopWord(this.p.sSenXFirst) ? 
      //       this.p.sSenXFirst.charAt(0).toLowerCase() + this.p.sSenX.substr(1) :
      //       this.p.sSenX;
      //       // console.log('STOP? - ', this.p.sSenXFirst, this.$store.getters.isStopWord(this.p.sSenXFirst), this.p.sSenXlc);

      //       // onwards
      //       if (bPubNth) {
      //         aStack.push({
      //           ...this.p.oArticle,
      //           copy: this.p.sSenX.trim() + '.',
      //         });
      //       } else {
      //         aStack.push({
      //           ...this.p.oArticle,
      //           copy: this.p.sPubMarkPlacement() + this.p.sErrorLinks,
      //         });
      //       }
      //       bPubNth = true;
      //     });

      //   });
      // }

      console.log("[Synth] -- Stack:", aStack);
      return aStack;
    },




    // keep generic, for lifting to Charlie.. 
    parseInLinks(sText, oOpts) {
      let aArticles = oOpts?.story?.articles;
      if (!aArticles) {
        console.warn(`No articles provided for parseInLinks()`, oOpts);
        return sText;
      }
      // track publishers... first link we see only (ambiguous? sort?)
      let aDone = {};
      // try to replace in each publisher/article
      aArticles.forEach(aArticle => {
        // make sure we've got a name (unlikely we don't!?)
        let sPubName = aArticle?.publisher.name;
        if (!sPubName) {
          return;
        }
        // once per publisher
        if (aDone[sPubName]) {
          return;
        }
        aDone[sPubName] = true;
        // build a regexp for this publisher.. (name wrapped in punctuation or space?)
        let sRegExpStr = `(?<=([\\W\\s]|^))${sPubName}(?=[\\W\\s])`;
        let sRegExp = new RegExp(sRegExpStr, 'gi');
        // build the link
        // let sIconSrc = aArticle.publisher.icon;
        // let sPubIcon = (sIconSrc && (sIconSrc != '(unknown)')) ? `<img src="${sIconSrc}">` : '';
        let sLink = ` <a data-click="outclick" data-article="${aArticle.slug}">${sPubName}</a> `;
        // let sPubMark = ` <span class="pub-mark">${sPubIcon} ${sLink}</span> `;
        let sPubMark = sLink;
        // regexp in place..
        sText = sText.replace(sRegExp, sPubMark);
        // done
        // console.log(`RegExp: /${sRegExpStr}/${sLink}/; `, sRegExp);
      });
      return sText;
    },


















    /**
     *  ---------------------------------------------------------------
     *  v5 - /next -> Homepage Controls..
     *  _______________________________________________________________
     */
    v5_getColourArray() {
      // ['yellow-tone-3', 'orange-tone-3', 'blue-tone-3', 'red-tone-3', 'green-tone-3', 'purple-tone-3']
      return ['#FFDE00', '#FF5717', '#0073EC', '#EE002C', '#14B31F', '#571FB1']
    },

    v5_getPlaylist() {
      // WARNING:: STRUCTURE MAY CHANGE - Return playlist only
      return this.$store.getters.getNext(this.slug)?.playlist?.decks || {
        sections: {},
        stories: [],
      };
    },

    v5_getSections() {
      // console.log('v5 PREBUILD: ', this.v5PreBuiltSections);
      return this.v5PreBuiltSections;
    },

    v5_buildSections() {
      // no-one likes object really.. convert to array...
      let aPlaylist = Object.values(this.v5_getPlaylist()?.sections);
      
      if (!aPlaylist.length) {
        // console.log(`[GSX] - Still Loading?: `, aPlaylist);
        return [];
      }

      // reset the dedupe stacks!
      this.v5Dedupe = {
        stories: {},
        locales: {},
        topics: {},
      };

      // Little bit of debug output
      // aPlaylist.forEach(section => {
      //   // console.log(`Section: ${section.super}:${section.group}, Score: ${section.profile?.sum}, Delta: ${section.profile?.delta?.pos}`);
      // });

      
      // mirror the /next intent:

      // iterate -- with low thresholds for duplication, read-state etc.
      //         -- attempt to fill 4x sections (2x big, 2x mixed)
      //         -- adjust thresholds till full (or run out)

      let bOkay = true; // loop catch
      let iEmergency = 500; // emergency loop break
      let iNext = 0;
      let oSections = {
        'focus:domestic-emerging' : {
          item: false,
        },
        'focus:foreign-emerging' : {
          item: false,
        },
        'focus:domestic' : {
          item: false,
        },
        'focus:foreign' : {
          item: false,
        },
        'other:foreign:map' : {
          items: [],
        },
        'other:domestic' : {
          items: [],
        },
        'other:foreign' : {
          // hide: true, // hacky -- need some logic for this really
          items: [],
        },
        'other:foreign-emerging' : {
          // hide: true, // hacky -- need some logic for this really
          items: [],
        },
        'other:domestic-emerging' : {
          // hide: true, // hacky -- need some logic for this really
          items: [],
        },
      };

      let oRules = {
        minFocusCX: 99, // minumum stories for focus sections
      };

      // console.log(`[GSX] ------------------------------`);
      // console.log(`[GSX] ------------------------------`);
      // console.log(`[GSX] ------------------------------`);
      // console.log(`[GSX] - Rules: `, oRules);

      // backup thresholds.. they get cranked up during iterations (deep clone!)
      let oThresholdBackup = JSON.parse(JSON.stringify(this.v5Thresholds));
      let iKeysUsed = [];


      if (aPlaylist.length >= 0) {
        // return;
      }

      let bDebugNextBuild = false;

      do {
        // console.log(`[GSX] - ------------------------- [${iNext} | ${iEmergency}] `);

        // reload?
        if (iNext >= (aPlaylist.length -1)) {
          // bDebugNextBuild && console.log(`%c[GSX] - Lap completed.  Restarting! ****************************** `, 'color: #8888ff;');
          this.v5_crankThresholds();
          iNext = 0;
        }

        // grab the next playlist item to consider
        let oPlayItem = aPlaylist[iNext];
        let iPlayItem = iNext;

        // bump the itterations
        iNext ++;
        iEmergency --;


        // carry on?
        bOkay = (iEmergency > 0);

        if (!bOkay) {
          // bDebugNextBuild && console.log(`%c[GSX] - FINISHED! *** EMERGENCY BREAK *** (${iEmergency}) ****************************** `, 'color: #ff4444;');
          break;
        }




        // now, skip anything we've already used
        if (iKeysUsed.includes(iPlayItem)) {
          // bDebugNextBuild && console.log(`%c[GSX] - [${iPlayItem}] Used once. Skipping`, 'color: #444444;');
          continue;
        }

        

        // skip once we have nothing
        if (!oPlayItem) {
          // console.log(`[GSX] - Breaking (null : ${iNext}) ****************************** `);
          break;
        }

        // console.log(`[GSX] - Item [${iNext}] [${oPlayItem.super}:${oPlayItem.group}]: `, oPlayItem);

        
        
        // markers for later stuff.
        let bAdded = false; // when true, we tidy up the loop by stacking stuff for future deduplication




        // remove duplicates as we cycle round... to stop similar/repeating sections appearing
        // NB: THIS IS NOT ABOUT read-state!
        oPlayItem.dedupe = {};

        let iDDSxStory = this.v5_getDedupeScore_Story(oPlayItem);
        let iDDSxLocales = this.v5_getDedupeScore_Locales(oPlayItem);
        let iDDSxTopics = this.v5_getDedupeScore_Topics(oPlayItem);

        // thresholds
        let bDDSxStory = (iDDSxStory > this.v5Thresholds.dedupe.stories);
        let bDDSxLocales = (iDDSxLocales > this.v5Thresholds.dedupe.locales);
        let bDDSxTopics = (iDDSxTopics > this.v5Thresholds.dedupe.topics);
        
        if (bDDSxStory || bDDSxLocales || bDDSxTopics) {
          bDebugNextBuild && console.log(`%c[GSX] - Item [${iNext}] [${oPlayItem.super}:${oPlayItem.group}] ---- SKIPPING [DUPLICATE] ---- DDSx: Story: [${bDDSxStory}] ${iDDSxStory}, Locale: [${bDDSxLocales}] ${iDDSxLocales}, Topic: [${bDDSxTopics}] ${iDDSxTopics}`, 'color: #ff22ff;');
          oPlayItem.skipped = 'dedupe';
          continue;
        }



        // READ STATE THRESHOLDS...
        let iRSxStory = this.v5_getReadState_Story(oPlayItem);
        let iRSxLocales = this.v5_getReadState_Locales(oPlayItem);
        let iRSxTopics = this.v5_getReadState_Topics(oPlayItem);

        // thresholds
        let bRSxStory = (iRSxStory > this.v5Thresholds.readstate.stories);
        let bRSxLocales = (iRSxLocales > this.v5Thresholds.readstate.locales);
        let bRSxTopics = (iRSxTopics > this.v5Thresholds.readstate.topics);

        if (bRSxStory || bRSxLocales || bRSxTopics) {
          bDebugNextBuild && console.log(`%c[GSX] - Item [${iNext}] [${oPlayItem.super}:${oPlayItem.group}] ---- SKIPPING [READSTATE] ---- [${this.v5Thresholds.readstate.stories}] RSx: Story: ${iRSxStory}, Locale: ${iRSxLocales}, Topic: ${iRSxTopics}`, 'color: #ff2222;');
          oPlayItem.skipped = 'readstate';
          continue;
        }



        oPlayItem.skipped = '';
        bDebugNextBuild && console.log(`[GSX] - Item [${iNext}] [${oPlayItem.super}:${oPlayItem.group}] ---- READSTATE ---- DDX: Story: ${iRSxStory}, Locale: ${iRSxLocales}, Topic: ${iRSxTopics}`);


        bDebugNextBuild && console.log(`[GSX] - Item [${iNext}] [${oPlayItem.super}:${oPlayItem.group}]: ---- -------- ---- DDX: Story: ${iDDSxStory}, Locale: ${iDDSxLocales}, Topic: ${iDDSxTopics}`);

        
        // okay, what are we left with..
        let bTooShort = (oPlayItem.cx < oRules.minFocusCX); // consider this too short for "focus"
        let bFocusFull = oSections[`focus:${oPlayItem.super}`]?.item; // "focus" for this super already full

        // console.log(`[GSX] - SHORT - [other:${oPlayItem.group}] (nix: ${oPlayItem.super}:) -- TooShort: ${bTooShort} (${oPlayItem.cx} stories), FocusFull: ${bFocusFull}`);
        
        // short section OR focus is full; add to 'other:*'
        if (bTooShort || bFocusFull) {
          // console.log(`[GSX] - SHORT - [other:${oPlayItem.group}] (nix: ${oPlayItem.super}:)`);
          if ((oPlayItem.super == 'foreign') && (oPlayItem.map == 'locale')){
            // country-specific, 'other' foreign stories feed the map 
            oSections[`other:${oPlayItem.super}:map`].items.push(oPlayItem);
          } else {
            // everthing else goes elsewhere
            oSections[`other:${oPlayItem.super}`].items.push(oPlayItem);
          }
          // remember to add this to dedupe library
          bAdded = 'other';
        } else {
          // not too short, focus isn't already full..
          if (!bTooShort && !bFocusFull) {
            // console.log(`[GSX] - FOCUS - [${oPlayItem.super}:${oPlayItem.group}]`);
            // set the "focus" section for this super...
            oSections[`focus:${oPlayItem.super}`].item = oPlayItem;
            // remember to add this to dedupe library
            bAdded = 'focus';
          } else {
            // bDebugNextBuild && console.log(`%c[GSX] - Item [${iNext}] [${oPlayItem.super}:${oPlayItem.group}] ---- DISCARD ---- `, 'color: #ff6666;');
          }
        }
        
        
        
        
        
        
        if (bAdded) {
          // bDebugNextBuild && console.log(`%c[GSX] - Item [${iNext}] [${oPlayItem.super}:${oPlayItem.group}]: --- KEEP (Stack for DeDupe)`, 'color: #22ff22; font-weight: bold;');
          this.v5_setDedupeForItem(oPlayItem);
        }

        // obviously, remove anything that's added..
        if (bAdded) {
          // console.log(`[GSX] - [${iPlayItem}] Used. Recording...`);
          iKeysUsed.push(iPlayItem);
        }




      } while (bOkay);

      // console.log(`[GSX] - Out of loop ****************************** `);
      // console.log(`[GSX] -- `, JSON.parse(JSON.stringify(oSections)))

      // reset thresholds (deep clone)
      this.v5Thresholds = JSON.parse(JSON.stringify(oThresholdBackup));
      // console.log('THRESHOLDS: ', this.v5Thresholds);






      /**
       *  --- CLEAN ---
       *  
       *  More logic to go here.  Limiting the list-size, looking at the user's day-journey etc.. re-ordering?
       *  
       *  .. for now just hide the default-hidden items & auto-clear the empty items
       */
      Object.keys(oSections).forEach((k) => {
        // remove empty items
        if ((oSections[k].item == false) || (oSections[k]?.items && !oSections[k]?.items.length)) {
          delete oSections[k];
        }
        // remove default-hidden items
        if (oSections[k]?.hide) {
          delete oSections[k];
        }
      });










      // console.log(`[GSX] - DeDupe: `, this.v5Dedupe);
      // console.log(`[GSX] - Output: `, oSections);


      // construct the final array:
      let aSections = [];

      if (!this.$store.getters.isLoggedIn) {
        aSections.push({
          pattern: 'GuestOnboarding',
          data: {}
        });
      }

      // Add AccountSetup card to flow
      // For prototyping, include at the top
      const oneDay = 24 * 60 * 60 * 1000;
      let lastSkipDate = localStorage.getItem('accountSetupSkip');
      let today = new Date().getTime();
      var diffDays = Math.round(Math.abs((lastSkipDate - today) / (oneDay)));

      if (this.$store.getters.isLoggedIn && diffDays > 1) {
        // Difference is greater than X days. Show section and remove local storage date
        localStorage.removeItem('accountSetupSkip');

        if (!this.$store.getters.hasApp) {
          // Within app
          if (!this.$store.getters.getAccount.details.updatesEmailOn || !this.$store.getters.getAccount.details.updatesMobileOn || !this.$store.getters.isPro) {
            aSections.push({
              pattern: 'AccountSetup',
              data: {}
            });
          }
        } else {
          if (!this.$store.getters.getAccount.details.updatesEmailOn || !this.$store.getters.isPro) {
            aSections.push({
              pattern: 'AccountSetup',
              data: {}
            });
          }
        }
      }

      Object.entries(oSections).forEach(([kSection, oSection]) => {
        // if (kSection == 'focus:domestic') {
        //   return;
        // }
        // disentangle which 'reason' this section is
        let [sMode, sFD, sSubType] = kSection.split(':');
        // ignore '-emerging' stuff..
        sFD = sFD.replace(/-emerging/, '');
        // debug.
        // console.log(`[GSX] - PLACING: sMode: ${sMode}, sFD: ${sFD}, sSubType: ${sSubType}`); 
        // track that into the object in case it's useful
        oSection.section = {
          mode: sMode,
          fd: sFD,
        };
        // choose a template.. (simple for now..)
        if (sMode == 'focus') {
          oSection.pattern = 'PlaylistFocusStory';
        } else {
          if ((sFD == 'foreign') && (sSubType == 'map')) {
            oSection.pattern = 'PlaylistBigMap';
          } else {
            oSection.pattern = 'PlaylistThreeStories';
          }
        }

        // migrate the data across...
        oSection.data = oSection.item || oSection.items;
        delete oSection['item'];
        delete oSection['items'];

        // set some headings?
        if ((kSection == 'other:domestic') && !oSections['focus:domestic']) {
          oSection.section.name = `Today's local stories`;
          oSection.section.reckoner = `Not everything. Enough.`;
          oSection.section.shift = -1;
        }

        // debug a little?
        // nope

        // stack the section
        aSections.push(oSection);
      });

      // Sep 22 - Only for Guests.  The GoPro message is expressed (occasionally) in the UserEnd card/section
      // if (!this.$store.getters.isLoggedIn || !this.$store.getters.isPro) {
      if (!this.$store.getters.isLoggedIn) {
        // Push CTA Section
        aSections.push({
          pattern: 'PlaylistCTA',
          data: {}
        });
      }

      // Push Personal Section
      // aSections.push({
      //   pattern: 'UserStories',
      //   data: {}
      // });

      // Push Dashboard Section
      if (this.$store.getters.isLoggedIn && this.$store.getters.isAlpha) {
        aSections.push({
          pattern: 'UserDashboard',
          data: {}
        });
      }

      // Push Dashboard Section
      if (this.$store.getters.isLoggedIn) {
        aSections.push({
          pattern: 'UserEnd',
          data: {}
        });
      }

      // SHIFT ANY ELEMENTS?
      aSections.forEach((a, i) => {
        if (a.section?.shift) {
          // set where we insert
          var insert = i + a.section?.shift;
          // grab the raw element
          var ele = aSections[i];
          // unset the shift
          ele.section.shift = 0;
          // splice out the element
          aSections.splice(i, 1);
          // splice it back in
          aSections.splice(insert, 0, ele);
        }
      });



      // console.log(`[GSX] - v5 BUILD COMPLETE -- SECTION = `, aSections);

      this.v5PreBuiltSections = aSections;

      return this.v5PreBuiltSections;
    },


    v5_crankThresholds() {
      
      // increase the deduplication threshold a smidge..
      this.v5Thresholds.dedupe.stories *= 2;
      this.v5Thresholds.dedupe.topics *= 2;
      this.v5Thresholds.dedupe.locales *= 2;

      this.v5Thresholds.dedupe.addscore *= 2;

      // double the readstate delta thresholds..
      this.v5Thresholds.readstate.stories *= 2.5; // <<-- careful adjustment.. 
      this.v5Thresholds.readstate.topics *= 2;
      this.v5Thresholds.readstate.locales *= 2;

      // console.log(`[GSX] - Cranked Thresholds - Readstate: ${this.v5Thresholds.readstate.stories}`);
    },

    v5_getDedupeScore_Story(oPlayItem) {
      // console.log(`[GSX:DeDupe] - GET [${oPlayItem.super}:${oPlayItem.group}]`);

      let iSeen = oPlayItem.stories.filter(oStoryStem => {
        return this.v5Dedupe.stories[oStoryStem.slug];
      }).length;

      oPlayItem.dedupe.stories = iSeen / oPlayItem.cx
      // return the % of stories we've already seen
      return oPlayItem.dedupe.stories;
    },

    v5_getDedupeScore_Locales(oPlayItem) {
      // don't 'locally' dedupe local stories
      if (oPlayItem.super == 'domestic') {
        return 0;
      }
      // as you were.
      oPlayItem.dedupe.locales = oPlayItem.stories.reduce((iStorySum, oStoryStem) => {
        let oStory = this.v5_hydrateStory(oStoryStem.slug);
        // get all of the locale scores x dedupe scores..
        return oStory.locale ? iStorySum + oStory.locale.reduce((iLocaleSum, oLocale) => {
          return iLocaleSum + (oLocale.score * (this.v5Dedupe.locales[oLocale.topic_code] || 0));
        }, 0) : 0;
      }, 0);
      return oPlayItem.dedupe.locales;
    },

    v5_getDedupeScore_Topics(oPlayItem) {
      oPlayItem.dedupe.topics = oPlayItem.stories.reduce((iStorySum, oStoryStem) => {
        let oStory = this.v5_hydrateStory(oStoryStem.slug);
        // get all of the topic scores x dedupe scores..
        return oStory.topics ? iStorySum + oStory.topics.reduce((iTopicSum, oTopic) => {
          return iTopicSum + (oTopic.score * (this.v5Dedupe.topics[oTopic.topic_code] || 0));
        }, 0) : 0;
      }, 0);
      return oPlayItem.dedupe.topics;
    },


    v5_getReadState_Story(oPlayItem) {
      return oPlayItem.profile?.read?.px || 0;
    },

    v5_getReadState_Locales(oPlayItem) {
      return oPlayItem.super == 'domestic' ? 0 : (oPlayItem.profile?.topics?.stats?.deltas?.locales || 0);
    },

    v5_getReadState_Topics(oPlayItem) {
      return oPlayItem.profile?.topics?.stats?.deltas?.topics || 0;
    },
    

    v5_setDedupeForItem(oPlayItem) {
      // console.log(`[GSX:DeDupe] - SET [${oPlayItem.super}:${oPlayItem.group}]`);
      // fetch the story
      
      // run through each story in the playlist item
      oPlayItem.stories.forEach(oStoryStem => {
        
        // stack the scores of stories, locales & topics
        let oStory = this.v5_hydrateStory(oStoryStem.slug);

        // story
        if (!this.v5Dedupe.stories[oStory.slug]) {
          this.v5Dedupe.stories[oStory.slug] = this.v5Thresholds.dedupe.addscore;
        } else {
          this.v5Dedupe.stories[oStory.slug] += this.v5Thresholds.dedupe.addscore;
        }
        
        // locales
        oStory.locale?.forEach(o => {
          this.v5Dedupe.locales[o.topic_code] = (this.v5Dedupe.locales[o.topic_code] * this.v5Thresholds.dedupe.addscore) + o.score || o.score;
        });

        // topics
        oStory.topics?.forEach(o => {
          this.v5Dedupe.topics[o.topic_code] = (this.v5Dedupe.topics[o.topic_code] * this.v5Thresholds.dedupe.addscore) + o.score || o.score;
        });
      });
    },

    v5_hydrateStory(sSlug) {
      return this.rawStories?.find(a => a.slug == sSlug) || {};
    },













    /**
     *  ---------------------------------------------------------------
     *  
     *  Methods for v4 / personalised/improved homepage story selection
     *  _______________________________________________________________
     */

    v4_hydrate(aSlugs) {
      // console.log(this.rawStories);
      return aSlugs.map(s => {
        return this.rawStories.find(a => a.slug == s);
      });
    },

    v4_dehydrate(aStories) {
      return aStories.map(a => a.slug);
    }, 

    v4_getFollowUps() {
      return this.v4_dehydrate(this.rawStories);
    },

    // build some edition profiling to understand the score balance
    // NB - this is super complex really cos score are a function of
    //      how many publishers we cover! they're not (nor can they
    //      easily be) normalised...
    v4_getSection(sKey) {
      return this.rawEdition?.sections?.find(a => a.code == sKey) || {
        stories: [],
      };
    },

    v4_doProfiling() {

      // standard deviation of story scores
      return {
        all: this.v4_profileScores(this.rawStories),
        domestic: this.v4_profileScores(this.v4_hydrate(this.v4_getSection('domestic').stories)),
        foreign: this.v4_profileScores(this.v4_hydrate(this.v4_getSection('foreign').stories)),
      }
    },

    v4_profileScores(stories) {
      let scores = [...stories].map(a => a.score);
      const n = scores.length;
      const avg = scores.reduce((a, b) => a + b) / n
      const std = Math.sqrt(scores.map(x => Math.pow(x - avg, 2)).reduce((a, b) => a + b) / n);
      const dev = std/avg;
      
      return {
        len: n,
        avg: avg,
        std: std,
        dev: dev,
      };
    },

    v4HomeStructure() {
      if (!this.rawStories) {
        return this.v4HomeLoading();
      }
      /**
       * Build the HomePage - generally as follows:
       * 
       * - Biggest thing, unread, here in the UK
       * - Biggest things, unread, abroad
       * - Biggest story of all
       * - Personal tracking
       * - Dashboard
       */

      // let oProfiling = this.v4_doProfiling();

      // console.warn(`v4 :: EDITION PROFILE: `, oProfiling);     

      // 1. get the most compelling "Deep Dive" story
      let oDeepDive = this.v4_getLongStory();

      // 2. get the most compelling "Domestic" section
      

      // 3. construct international sections

      // 3.a -- Major international focus?
      let oIntlFocus = this.v4_getIntlFocus();

      // 3.b -- Subordinate international collection..
      // let oIntlCollection = this.v4_getIntlCollection();

      return [
        {
          head: {
            show: true,
            title: "International focus",
            subtitle: "Here is a specific area of international focus",
          },
          layout: [
            {
              width: 4,
              component: 'DeepDive',
              config: {
                story: oIntlFocus.core.slugs[0],
              }
            },
            {
              width: 1,
              component: 'MapSimple',
              config: {
                highlight: oIntlFocus.core.locales,
              }
            },
          ]
        },

        // {
        //   head: {
        //     show: true,
        //     title: "International collection",
        //     subtitle: "Here is a collection of international stories",
        //   },
        //   layout: [
        //     {
        //       width: 4,
        //       component: 'Map',
        //       config: {
        //         story: oIntlCollection,
        //       }
        //     },
        //   ]
        // },

        {
          head: {
            show: true,
            title: "Worth a deep dive",
            subtitle: "Here's a long-running story about {xx} you've not read.",
          },
          layout: [
            {
              width: 4,
              component: 'DeepDive',
              config: {
                story: oDeepDive,
              }
            },
            {
              width: 1,
              component: 'Timeline',
              config: {
                story: oDeepDive,
              }
            },
          ]
        },

      ]

      // return this.v4HomeDemoOne();
    },

    v4HomeLoading() {
      return [
        {
          head: {
            show: false,
          },
          layout: [
            {
              width: 5,
              component: 'loading',
            },
          ],
          tools: {
            show: false,
          }
        },
      ]
    },

    v4HomeDemoOne() {
      return [
        {
          head: {
            title: "This is a fallback/demo section",
            subtitle: "It appears when homepage content has loaded but hasn't been figured out yet."
          },
          layout: [
            {
              width: 2,
              component: 'debug',
            },
            {
              width: 2,
              component: 'debug',
            },
            {
              width: 1,
              component: 'debug',
            },
          ],
        },
      ]
    },

    v4_getLongStory() {
      let all = [...this.rawStories];
      // sort by length
      all.sort((a,b) => {
        let iA = Math.log(a.score) * Math.log(a.days);
        let iB = Math.log(b.score) * Math.log(b.days);
        return iA > iB ? -1 : 1;
      });
      // debug the top 10
      all.slice(0,10).forEach(() => {
        // let iLog = Math.log(s.score);
        // console.log(`v4_getLongStory: [${i}] :: ${s.slug} - ${s.days} days, ${s.score} (${iLog}) score = ${s.name}`);
      })
      // pick one (simplistically for now)
      let oChosen = all.slice(0,1);
      let sSlug = this.v4_dehydrate(oChosen);
      // show us..
      // console.log(`v4_getLongStory: CHOSEN: ${sSlug} ${oChosen[0].name}`);
      // and return just the slug
      return sSlug
    },

    v4_getIntlFocus() {
      let oSection = this.v4_getSection('foreign');
      // console.log('v4_getIntlFocus :: ', oSection);

      // explore the locale_map
      let oMap = oSection.layout.locale_map;
      let aScores = [];
      // 
      for (const [kMap, vMap] of Object.entries(oMap)) {
        // hack (ignore US & UK for 'foreign' sections)
        if (['UK','GB','US'].includes(kMap)) { continue; }
        // sum the scores of the stories
        let iScore = this.v4_hydrate(vMap).map(s => s.score).reduce((acc, x) => acc + x);
        // track the
        aScores.push(iScore);        
      }
      // calc the average & dev
      let n = aScores.length;
      let iMean = aScores.reduce((acc, x) => acc + x) / n;
      let iDev = Math.sqrt(aScores.map(x => Math.pow(x - iMean, 2)).reduce((a, b) => a + b) / n);
      // is the first block more than 2 deviations out?
      let iFocus = (aScores[0] - iMean) / iDev;
      // console.log(`v4: Mean: ${iMean}, Deviation: ${iDev}, Position: ${iFocus}`);

      // only build the IntlFocus section in extreme cases...
      if (iFocus < 2.0) {
        // console.log('Top locale focus is less than 2 deviations to normal.  Not over-focusing.');
        return false;
      } 

      // if the top focus is multi-lateral, include the individual contries too
      let oFocus = {
        core: {
          locales: Object.keys(oMap)[0].split(','),
          slugs: Object.values(oMap)[0],
          score: aScores[0],
        },
        also: {},
      }
      for (const [kMap, vMap] of Object.entries(oMap)) {
        let aLocales = kMap.split(',');
        if ((aLocales.length == 1) && (oFocus.core.locales.includes(aLocales[0]))) {
          oFocus.also[kMap] = vMap;
        }
      }

      // console.log(`v4 FOCUS LOCALE:`, oFocus);
      return oFocus;
    },

    v4_getIntlCollection() {
      let all = [...this.rawStories];
      // sort by length
      all.sort((a,b) => {
        let iA = Math.log(a.score) * Math.log(a.days);
        let iB = Math.log(b.score) * Math.log(b.days);
        return iA > iB ? -1 : 1;
      });
      // debug the top 10
      all.slice(0,10).forEach(() => {
        // let iLog = Math.log(s.score);
        // console.log(`v4_getLongStory: [${i}] :: ${s.slug} - ${s.days} days, ${s.score} (${iLog}) score = ${s.name}`);
      })
      // pick one (simplistically for now)
      let oChosen = all.slice(0,1);
      let sSlug = this.v4_dehydrate(oChosen);
      // show us..
      // console.log(`v4_getLongStory: CHOSEN: ${sSlug} ${oChosen[0].name}`);
      // and return just the slug
      return sSlug
    },

    v4NodeBody(model, aFocus) {
      // quick hack way to extract some content (for dogfood-development)
      // console.log('Node: Content', this.nodeContent, this.p);
      
      // let aFocus = this.nodeContent?._debug?.articles?.focus || {};
      let aStack = [];

      ['med', 'neg', 'pos'].forEach(k => {
        // oftimes missing:
        if (!aFocus[k]) return;

        // grab the article:

        this.p.oArticle = model?.articles?.find(o => o.slug == aFocus[k].article) || {};
        this.p.oAuthor = this.p.oArticle.author;

        // console.log(k, aFocus, aFocus[k].article);
        // console.log(model);
        // console.log(this.p.oArticle);
        // console.log(this.p.oAuthor);
        // indi or pool?

        // display: inline-block; width: 18px; height: 18px;
        // width: 100%; height: 100%; object-fit: cover; border-radius: 50%;
        // markers

        this.p.sErrorLinks = '';
        // add error-reporting
        if (this.isAlpha) {
          this.p.sErrorLinks = `
          <br/>
          <span class="pub-errors">
            (<span data-click="report" data-article="${this.p.oArticle.slug}" data-type="body">BODY</span> |
            <span data-click="report" data-article="${this.p.oArticle.slug}" data-type="author">AUTHOR</span>)
          </span>
          `;
        }

        let sIconSrc = this.p.oArticle.publisher.icon;
        this.p.sPubIcon = (sIconSrc && (sIconSrc != '(unknown)')) ? `<img src="${sIconSrc}">` : '';
        this.p.sPubLink = `<a data-click="outclick" data-article="${this.p.oArticle.slug}">${this.p.oArticle.publisher.name}</a>`;
        this.p.sPubMark = `<span class="pub-mark">${this.p.sPubIcon} ${this.p.sPubLink}</span>`;

        // o.text[0].toLowerCase() + o.text.slice(1);
        this.p.sPubMarkPlacement = this.templates.attr[aStack.length? 'nth' : 'first' ]?.[this.p.oAuthor.type] || this.templates.attr['_default']; 
        

        let bPubNth = false;

        // add the sentences:
        Object.values(aFocus[k]?.selection)?.forEach(o => {
          this.p.sSenX = o?.text?.replace(/\.$/,'');
          if (!this.p.sSenX) return;
          if (bPubNth) {
            aStack.push({
              ...this.p.oArticle,
              copy: this.p.sSenX + '.',
              senid: o.slug,
            });
          } else {
            aStack.push({
              ...this.p.oArticle,
              copy: this.p.sPubMarkPlacement() + this.p.sErrorLinks,
              senid: o.slug,
            });
          }
          bPubNth = true;
        });

      });
      // console.log("Stack:", aStack);
      return aStack;
    },


    /**
     *  ---------------------------------------------------------------
     *  
     *  v4 Ends -------------------------------------------------------
     *  _______________________________________________________________
     */




    nextUnreadFromObjectList(aList, sAfter) {
      // flag once we've found the search item 
      let bWound = false;

      return aList.find(slug => {
        if (slug == sAfter) {
          bWound = true;
          return; // continue now we've found our sAfter slug
        }
        if (slug == this.slug) {
          return; // continue past the 'current' slug even if sAfter isn't a thing
        }
        if (!bWound && sAfter) {
          return; // continue till we get past our sAfter slug
        }
        // read state? 
        let sReadState = this.$store.getters.readState(slug).code || '';
        if (sReadState && (sReadState != 'updated')) {
          return false; // continue past any "read" slugs
        }
        // all good - that's the one then!
        return true;
      });
    },

    clusterIsExpanded(i){
      return this.clusterGet(i)?.links?.includes(this.$route.params.node) ||
             this.clusterGet(i)?.links?.includes(this.$route.params.card);
    },

    clusterDate(i) {
      return this.clusterGet(i)?.stats?.mid;
    },

    clusterUpdateStatement(i) {
      return this.clusterDate(i);
    },
    
    clusterHeadline(i) {
      console.log("CLUSTER: ",  this.clusterGet(i))
      return this.clusterArticle(i)?.name;
    },
    
    clusterSynopsis(i) {
      return this.clusterArticle(i)?.synopsis;
    },

    clusterTopics(i) {
      return this.clusterGet(i).topics.filter(t => t.type_code !== 'other') || [];
    },

    clusterPublishers(i) {
      let story = this.storyGet();
      let cluster = this.clusterGet(i);
      let dedupe = {};
      cluster.links.forEach(s => {
        let pub = story.articles.find(a => a.slug == s).publisher;
        pub.article = story.articles.find(a => a.slug == s);
        return dedupe[pub.code] = pub;
      });
      return Object.values(dedupe).filter(a => a.icon);
    },

    // find the article belonging to the [*first*] slug listed in the cluster
    // [*first*] - requires more thought
    clusterArticle(i){
      let cluster = this.clusterGet(i);
      let story = this.storyGet();
      // // console.log('-------', i, cluster);
      return story.articles.find(a => a.slug == cluster.links[0]);
    },

    clusterSlug(i){
      return this.clustersGet()?.[i].links?.[0];
    },

    clusterGet(i){
      return this.clustersGet()?.[i];
    },

    clustersGet(){
      let aClusters;
      let isPro = this.$store.getters.isPro;
      let isUser = this.$store.getters.isLoggedIn;
      
      aClusters = this.storyGet().clusters?.timeseries;

      if (!aClusters) {
        return false;
      }

      aClusters = [
        // mark our clusters as "Node" components
        ...aClusters.map(a => {
          a.component = 'Node';
          a.card = {
            show: {
              signUp: false,
              goPro: false
            }
          }
          return a;
        }),
        // add the end card (always present but will become dynamic)
      ];

      // Check if user is signed up or pro. If not, adjust card to hide
      for (let idx = 0; idx < aClusters.length; idx++) {
        if (idx >= 1) {
          // Always show first 2 nodes
          if (!isUser || !isPro) {
            aClusters[idx].card = {
              show: {
                signUp: !isUser,
                goPro: isUser && !isPro,
              }
            }
          }
        }
      }

      // // Push final end card
      // aClusters.push(
      //   {
      //     // @ JASON -- EXAMPLE LAST/END CARD
      //     component: 'EndCard',
      //     links: [aClusters[aClusters.length-1].links[0] + '/endCard', 'endCard'],
      //     card: {
      //       // We can experiment with what goes here... 
      //       title: "That's all folks.",
      //       message: "You've read everything in this story.",
      //       show: {
      //         signUp: !isUser,
      //         goPro: isUser && !isPro,
      //       }
      //     }
      //   }
      // );



      // // more days?
      // let iMoreDays = aClusters.length - 1;
      // let sMoreDays = this.$options.filters.plural(iMoreDays, 'day');
      // let aMore = isUser && isPro ? aClusters.slice(1) : [];
      // let sMessage = isPro ? `There are ${iMoreDays} more ${sMoreDays} of reporting on this story.` :
      //                        `Unlock ${iMoreDays} more ${sMoreDays} of reporting on this story.`

      // // Possibly (currently always) stack in a 'second' card
      // if (aClusters.length > 2) {
      //   aClusters = [aClusters[0], {
      //     // @ JASON -- EXAMPLE MID CARD ... could be multiples of these?
      //     component: 'EndCard',
      //     links: [aClusters[0].links[0] + '/midCard', 'midCard'],
      //     card: {
      //       // We can experiment with what goes here... 
      //       title: `${iMoreDays} ${sMoreDays}`,
      //       message: sMessage,
      //       next: [
      //         this.$store.getters.getV3HomeTEMP?.data?.sections?.[3]?.stories?.[0],
      //         this.$store.getters.getV3HomeTEMP?.data?.sections?.[3]?.stories?.[1],
      //       ],
      //       show: {
      //         signUp: !isUser,
      //         goPro: isUser && !isPro,
      //       }
      //     }
      //   }, ...aMore]
      // }

      return aClusters;

    },

    storyGet(){
      return this.$store.getters.getStoryV3(this.slug);
    },

    storyPublishersGet() {
      let aAllPublishers = this.storyGet()?.articles?.map(a => a.publisher);
      let aIndex = [];
      // count each publisher..
      aAllPublishers.forEach(a => {
        if (!aIndex[a.code]) {
          aIndex[a.code] = {
            cx: 1,
            pub: a, 
          };
        } else {
          aIndex[a.code].cx ++;
        }
      });
      return aIndex;
    },

    




























    parseEdition() {
      // pull .. and then do a little FE mapping based on the API suggestions/analysis (personalisation merge here?)
      // let aMainSections = this.$store.getters.getLibraryItem('edition_v3')?.sections || [];
      let aMainSections = this.rawEdition.sections || [];
      // let aSections = [];


      let sEditionCode = this.rawEdition?.meta?.locale.code;
      let bEditionOOB = !this.rawEdition?.meta?.locale.active;
      
      // console.warn("Parsing Edition: ", {
      //   sEditionCode: sEditionCode,
      //   bEditionOOB: bEditionOOB, 
      //   meta: this.rawEdition?.meta
      // });

      if (sEditionCode) {
        // record the user's preference
        this.$userEngine.setPref('edition.locale', sEditionCode);

        // alert people (once) if they're OOB
        if (bEditionOOB && (window.localStorage.getItem('bLocalOOBWarn') != sEditionCode)) {
          this.EventBus.$emit('showInboundMessage', {
            code: 'HASH_v3_inactive_edition',
            callback: (a) => {
              // record that they saw the message (to prevent it re-appearing!)
              window.localStorage.setItem('bLocalOOBWarn', sEditionCode);
              // record their vote for this locale! :o)
              if (a == 'vote') {
                this.$userEngine.setPref('edition.vote', sEditionCode);
              }
            },
          });
        }
      }

      // // console.log(' ----------------- SECTIONS ---------------- ');
      // // console.log(aMainSections);
      // // console.log(' ----------------- -------- ---------------- ');

      aMainSections = aMainSections.filter(a => a.stories.length);

      let aSeen = [];

      // we don't want to dedupe them all ('singles' for instance isn't used)
      let aIgnoreMapSections = ['singles']; 

      aMainSections.forEach(section => {
        // remove 'ignored' items from the 'remain' list
        section.layout.topic_map.remain = section?.layout?.topic_map?.remain?.filter(s => !section?.ignored?.includes(s));

        // dedupe..
        Object.keys(section.layout.topic_map).forEach(tmKey => {
          
          // console.log(tmKey, section.layout.topic_map[tmKey]?.length, section.layout.topic_map[tmKey]);

          if (!section.layout.topic_map[tmKey]){
            return;
          }

          if (aIgnoreMapSections.includes(tmKey)){
            return;
          }

          if (Array.isArray(section.layout.topic_map[tmKey])) {
            // filter any that have already been seen..
            section.layout.topic_map[tmKey] = section.layout.topic_map[tmKey].filter(s => !aSeen.includes(s));

            // filter what's been read
            section.layout.topic_map[tmKey] = section.layout.topic_map[tmKey].filter(this.ageStory);

            // debug
            // // console.log(`Array filtering: ${tmKey} Excluding: `, ...section.layout.topic_map[tmKey]);

            // record what we've seen
            aSeen.push(...section.layout.topic_map[tmKey]);
          } else {
            Object.keys(section.layout.topic_map[tmKey]).forEach(tmSubKey => {
              // filter any that have already been seen..
              section.layout.topic_map[tmKey][tmSubKey] = section.layout.topic_map[tmKey][tmSubKey].filter(s => !aSeen.includes(s));

              // filter what's been read
              section.layout.topic_map[tmKey][tmSubKey] = section.layout.topic_map[tmKey][tmSubKey].filter(this.ageStory);

              // debug
              // // console.log(`Object > Array filtering: ${tmKey} > ${tmSubKey}. Excluding: `, ...section.layout.topic_map[tmKey][tmSubKey]);

              // record what we've seen
              aSeen.push(...section.layout.topic_map[tmKey][tmSubKey]);
            });
          }
        });

      });

      aMainSections.forEach(aSection => {
        // // console.log("Section: ", aSection);
        // move all the read stories to another array (this is kinda repetitive but it's all fluid, right?..)
        aSection.readStories = aSection.stories.filter(s => {!this.ageStory(s);});
        aSection.stories = aSection.stories.filter(this.ageStory);
      });

      aMainSections = aMainSections.filter(a => a.stories.length);
      
      // // console.log(aSeen);
      // // console.log(' -----------------   ENDS   ---------------- ');

      // populate our datapoints for the rest of the app (home only, no topics for now..)
      this.unreadStories = aSeen;
      this.unreadSections = aMainSections;
      // return aMainSections;
    },

    readStory(storyOrSlug) {
      return this.clipStory(storyOrSlug, 0);
    },

    ageStory(storyOrSlug) {
      return this.clipStory(storyOrSlug, this.iClipAfter);
    },

    clipStory(storyOrSlug, iClipAfter) {
      // accepts just a slug (pointer) or a whole story
      let storySlug = storyOrSlug instanceof Object ? storyOrSlug.slug : storyOrSlug;

      // get its read-state
      let readState = this.readState(storySlug);

      // stuff we've NOT read is fine...
      if (!readState?.slug) {
        return true;
      }

      // stuff what's UPDATED is fine..
      if (readState.code == 'updated') {
        return true;
      }

      // // console.log(`ReadState: ${storySlug} = ${readState.name}`);
      let iWhen = new Date(readState.date).getTime(); // milliseconds..
      let iOld = new Date().getTime() - (60 * 1000 * iClipAfter);
      // // console.log(`Read ${s}? - `, (iWhen < iOld) ? 'Old' : 'New', iWhen, iOld, readState);
      if (iWhen < iOld) {
        // read longer ago than this.readHideAfter minutes
        return false;
      }
      // it's all good - keep it...
      return true;
    },

    readState(slug) {
      return this.$store.getters.readState(slug);
    },

    
























































    // get(sSlug) {
    //   console.warn("$dataEngine.get -- deprecated?");

    //   // while in demo, drop the v3
    //   sSlug = (sSlug||'/').replace(/^\/v3/,'');

    //   // handled by the router in due course :shrug:
    //   let aParams = (sSlug||'/').replace(/^\//,'').split('/');
    //   let sKey = aParams.shift();

    //   // debug
    //   // // console.log(`\x1B[33m DataEngine - Key: '${sKey}' - `, sSlug, aParams);

    //   let aTiles = [];

    //   switch (sKey) {
    //     case '':
    //       this.EventBus.$emit('view:set', 1000);
    //       // console.log('x');
    //       aTiles.push(...this.getHome());
    //       // console.log('y');
    //       break;
    //     case 'story':
    //       // super-basic check for an article-slug
    //       if (aParams.length == 2) {
    //         this.EventBus.$emit('view:set', 100);
    //         this.getArticle({story: aParams[0], slug: aParams[1]});
    //       } else {
    //         this.EventBus.$emit('view:set', 10);
    //         this.getStory({slug: aParams[0]});
    //       }
    //       break;
    //     case 'topic':
    //       this.EventBus.$emit('view:set', 100);
    //       this.getTopic({slug: aParams[0]});
    //       break;
    //     default:
    //       console.error(`Unrecognised subroute in dataEngine (${sKey})`);
    //   }

    //   // commit
    //   // console.log(`\x1B[33m DataEngine - Committing: `, sSlug, aTiles);
    //   store.commit('setV3Layout',{
    //     path: sSlug,
    //     layout: this.addUIDs(aTiles),
    //   });
    // },




    // addUIDs(aData) {
    //   // console.warn("Adding UIDs");
    //   // let iZIndex = 10;
    //   return aData.map(a => {
    //     let sRnd = Math.round(Math.random()*1000*1000*1000).toString(16).substr(0,6);
    //     let sId = `ostkx:${a.type||'x'}:${a.slug||'x'}:${sRnd}`;
    //     // // console.log(sId);
    //     a._uid =  sId;
    //     // a._zIndex = (iZIndex += 10);
    //     return a; 
    //   });
    // },


  
    // /**
    //  *  A utility for URL/route building, initially for centralising the
    //  *  testing/demo prefix but we'll see where it goes.. 
    //  */
    // buildURL(type, item) {
    //   // temporary
    //   let prefix = ''; // '/v3';
    //   let sUrl = false;
    //   // build the URL
    //   switch (type) {
    //     case 'story':
    //       sUrl = `${prefix}/story/${item.slug}`;
    //       break;
    //     case 'article':
    //       sUrl = `${item._base}/${item.slug}`;
    //       break;
    //     case 'topic':
    //       sUrl = `${prefix}/topic/${item.topic_code}`;
    //       break;
    //     default:
    //       sUrl = false;
    //       break;
    //   }
    //   // // console.log(`Built URL: (${reason})`, sUrl, item.type, item);
    //   return sUrl;
    // },
  
    // getCurrentPath(sPath) {
    //   return (sPath||'/'); // (sPath||'/v3');
    // },

    // /**
    //  *  A utility to manage whether to 'key' (uniquify) the root route component
    //  *  ------------
    //  *  Necessary for the v2 - v3 transition and possibly useful beyond.
    //  *  ------------
    //  *  /v3* -- return the same key to prevent component rebuild (v3 manages this)
    //  *  /*   -- all other urls, salt the component with the URL for constant rebuild on nav.
    //  */
    routerKey(router) {
      let sRouterKey = router.fullPath.match(/^\/v3/) ? `Static: v3` : `Dynamic: ${router.fullPath}`;
      return sRouterKey;
    }

  }, // end methods
})

Object.defineProperties(Vue.prototype, {
	$dataEngine: {
		get: function () {
			return dataEngine
		}
	}
})
