import Vue from 'vue';
import store from "./store";
import router from './router'

/**
 *  Ascii
 *  https://patorjk.com/software/taag/#p=display&h=2&v=3&c=c%2B%2B&w=%20&f=ANSI%20Regular&t=Topic
 */


const coachingEngine = new Vue({
  router,
  store,

  data: function () {
    return {}
  },

  computed: {
    
  },

  methods: {
    debug(){},

    notice(){
      console.log(`\x1B[35mCoachingEngine --`, ...arguments);
    },

    error(){
      console.log(`\x1b[35mCoachingEngine --`, ...arguments);
    },
  }, // end methods

  created() {
    this.notice("Up and running... ");
  },

  watch: {
    // $route(to, from) {
    //   //
    // },
    // foo(to) {
    //   this.bar();
    // },
    // prefs: {
    //   deep: true,
    //   handler: function(to, from) {

    //   }
    // }
  }
})

Object.defineProperties(Vue.prototype, {
  $coachingEngine: {
    get: function () {
      return coachingEngine
    }
  }
})
